// EmailVerification.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import './Auth.css';

const EmailVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.reload(); 
        if (user.emailVerified) {
          navigate('/');
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div className="auth-container">
      <h2>{t('Verifying your email...')}</h2>
      <p>{t('Please wait while we verify your email. You will be redirected shortly.')}</p>
    </div>
  );
};

export default EmailVerification;
