import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';  
import './PaymentSuccess.css';

const apiUrl = process.env.REACT_APP_API_URL;

const PaymentSuccess = () => {
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAuth();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');

  useEffect(() => {
    if (sessionId) {
      const fetchPaymentStatus = async () => {
        try {
          const response = await axios.get(`${apiUrl}/payment-status/${sessionId}`);
          if (response.data.success) {
            setMessage(t('Payment Successful! Your quotas have been updated.'));
          } else {
            setMessage(t('Payment failed or incomplete.'));
          }
        } catch (error) {
          console.error(t('Error fetching payment status:'), error);
          setMessage(t('An error occurred while fetching payment status.'));
        } finally {
          setLoading(false);
        }
      };

      fetchPaymentStatus();
    } else {
      setMessage(t('No session ID found.'));
      setLoading(false);
    }
  }, [sessionId, t]);  

  const handleStartNow = () => {
    navigate('/');
  };

  return (
    <div className="payment-success-container">
      <h2>{t('Payment Status')}</h2>
      {loading ? <p>{t('Loading...')}</p> : <p>{message}</p>}
      <button onClick={handleStartNow}>{t('Start Now')}</button>
    </div>
  );
};

export default PaymentSuccess;
