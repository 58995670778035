import React from "react";
import { useTranslation } from "react-i18next";
import "./FilePreview.css";

const FilePreview = ({ files, handleRemoveFile, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="file-preview">
      <h4>{t('Files Uploaded')}: {files.length}</h4>
      <ul className="file-list">
        {files.map((file, index) => (
          <li key={index} className="file-preview-item">
            <>
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="file-preview-image"
              />
              <div className="file-preview-image-name">
                <p>
                  {file.name.length > 20
                    ? `${file.name.slice(0, 20)}...`
                    : file.name}
                </p>
              </div>
            </>
            <span
              className="remove-single-invoice"
              onClick={() => handleRemoveFile(index)}
            >
              {loading ? "" : "✖"}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilePreview;
