import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import "./CompanyInformation.css";

const CompanyInformation = () => {
  const { i18n } = useTranslation();
  const user = useAuth();
  const [companyData, setCompanyData] = useState({
    companyName: "",
    vatId: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postalCode: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setCompanyData(userDoc.data().companyInfo || {});
          }
        } catch (err) {
          setError(i18n.t('Failed to fetch company information.'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [user, i18n]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user?.uid) {
      try {
        await updateDoc(doc(db, "users", user.uid), {
          companyInfo: companyData,
        });
        setSuccess(i18n.t('Company information updated successfully.'));
      } catch (err) {
        setError(i18n.t('Failed to update company information.'));
      }
    }
  };

  return (
    <div className="company-info-container">
      {loading ? (
        <p>{i18n.t('Loading...')}</p>
      ) : (
        <form onSubmit={handleSubmit} className="company-info-form">
          <label>
            {i18n.t('Company Name')}:
            <input
              type="text"
              name="companyName"
              value={companyData.companyName}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('VAT ID')}:
            <input
              // style={{ marginLeft: "60px" }}
              type="text"
              name="vatId"
              value={companyData.vatId}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('Country')}:
            <input
              // style={{ marginLeft: "78px" }}
              type="text"
              name="country"
              value={companyData.country}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('State')}:
            <input
              // style={{ marginLeft: "98px" }}
              type="text"
              name="state"
              value={companyData.state}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('City')}:
            <input
              // style={{ marginLeft: "107px" }}
              type="text"
              name="city"
              value={companyData.city}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('Address')}:
            <input
              // style={{ marginLeft: "74px" }}
              type="text"
              name="address"
              value={companyData.address}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <label>
            {i18n.t('Postal Code')}:
            <input
              // style={{ marginLeft: "45px" }}
              type="text"
              name="postalCode"
              value={companyData.postalCode}
              onChange={handleChange}
              className="company-info-input"
            />
          </label>
          <button type="submit" className="company-info-button">
            {i18n.t('Update')}
          </button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </form>
      )}
    </div>
  );
};

export default CompanyInformation;
