import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsOfService.css';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-of-service-container">
      <h1>{t('Terms of Service')}</h1>
      <p>{t('Effective Date')}: <strong>{t('June 19, 2024')}</strong></p>

      <section>
        <h2>{t('Introduction')}</h2>
        <p>
          {t('Welcome to VliSoft! These terms of service outline the rules and regulations for the use of VliSoft’s website and services. By accessing this website, we assume you accept these terms in full.')}
        </p>
      </section>

      <section>
        <h2>{t('Accounts and Services')}</h2>
        <p>
          {t('You must be at least 18 years old to use our services. You agree to provide accurate information and maintain the confidentiality of your account details.')}
        </p>
      </section>

      <section>
        <h2>{t('Payments and Subscriptions')}</h2>
        <p>
          {t('By subscribing to our services, you agree to pay the subscription fees. We use Third-Party service to analyze and process your payments securely, complying with GDPR.')}
        </p>
      </section>

      <section>
        <h2>{t('User Responsibilities')}</h2>
        <p>
          {t('You agree to use our services only for lawful purposes and not to engage in any activities that violate the rights of others or any applicable laws.')}
        </p>
      </section>

      <section>
        <h2>{t('Data Protection and Privacy')}</h2>
        <p>
          {t('We value your privacy and are committed to protecting your personal data. We comply with EU and Dutch data protection laws, including GDPR, to ensure the safety and confidentiality of your information.')}
        </p>
      </section>

      <section>
        <h2>{t('Termination')}</h2>
        <p>
          {t('We reserve the right to terminate your account if you violate these terms of service. You may also terminate your account at any time by contacting us.')}
        </p>
      </section>

      <section>
        <h2>{t('Changes to These Terms')}</h2>
        <p>
          {t('We may update these terms of service from time to time. We will notify you of any changes by posting the new terms on our website.')}
        </p>
      </section>

      <section>
        <h2>{t('Contact Us')}</h2>
        <p>
          {t('If you have any questions about these terms, please contact us at: ')}
          <a href="mailto:info@vlisoft.com">info@vlisoft.com</a>
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
