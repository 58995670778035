import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
          <Link to="/terms">{t('Terms of Service')}</Link>
          <a href="https://vlisoft.com" target='_blank' rel="noopener noreferrer">VliSoft</a>
        </div>
        <p>&copy; 2024 VliSoft. {t('All rights reserved')}.</p>
      </div>
    </footer>
  );
};

export default Footer;
