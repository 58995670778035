import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./AuthModal.css";

const AuthModal = ({ show, onHide }) => {
  const { i18n } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{i18n.t('Authentication Required')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {i18n.t('Free users can upload up to')} <span className="strong">{i18n.t('2 invoices')}</span> {i18n.t('at a time. To upload more invoices with a single click, sign up now and get')}<span className="strong">100 </span> {i18n.t('FREE invoice quotas!')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/login" className="navbar-button login">
          {i18n.t('Login')}
        </Link>
        <Link to="/signup" className="navbar-button signup">
          {i18n.t('Sign up')}
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default AuthModal;
