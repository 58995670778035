import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Auth.css';

const CheckEmail = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <div className="auth-container">
      <h2>{i18n.t('Check your email')}</h2>
      <p>{i18n.t('Please check your email (also check the junk mail box) for a verification link. After verifying, you can log in to your account.')}</p>
      <button onClick={() => navigate('/')}>{i18n.t('Back to Home')}</button>
    </div>
  );
};

export default CheckEmail;
