import axios from "axios";
import { formatTimestamp } from './formatTimestamp';
import ExcelJS from 'exceljs';
import { storage, db } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, arrayUnion, increment, getDoc   } from 'firebase/firestore';

const apiUrl = process.env.REACT_APP_API_URL;

export const uploadFiles = async (files, userId, setDownloadUrl, setExcelFilename, setFiles, maxRetries = 3, setProgress, setLoading, setUploadStart, usedQuota, setInvoiceData) => {
  setUploadStart(true);
  const formData = new FormData();
  files.forEach(file => {
    formData.append("files", file);
  });

  let attempts = 0;
  let sessionId;

  while (attempts < maxRetries) {
    try {
      const response = await axios.post(`${apiUrl}/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      sessionId = response.data.sessionId;
      if (!sessionId) {
        console.error("No sessionId received in response");
        throw new Error("No sessionId received in response");
      }
      break;
    } catch (error) {
      console.error(`Attempt ${attempts + 1} failed: ${error.message}`);
      attempts += 1;
    }
  }

  if (!sessionId) {
    console.error("Failed to get sessionId after multiple attempts");
    return { success: false, error: "Failed to get sessionId after multiple attempts" };
  }

  const pollForCompletion = async () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        setLoading(true);
        try {
          const progressResponse = await axios.post(`${apiUrl}/upload`, { sessionId });
          setProgress(progressResponse.data);

          if (progressResponse.data.completed) {
            clearInterval(interval);
            const invoiceData = progressResponse.data.data;
            const successFiles = files.length;

            // Returning success and data correctly
            resolve({ success: true, data: invoiceData });
          }
        } catch (error) {
          clearInterval(interval);
          reject({ success: false, error: "Error getting progress" });
        }
      }, 1000); // Poll every second
    });
  };

  try {
    const result = await pollForCompletion();
    setLoading(false);
    setUploadStart(false);
    return result;
  } catch (error) {
    console.error("Polling Error:", error);
    setLoading(false);
    setUploadStart(false);
    return { success: false, error: error.message };
  }
};

export const generateExcelAndUpload = async (invoiceData, userId, setDownloadUrl, setExcelFilename, setFiles, successFiles, usedQuota,) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Invoices');

  worksheet.columns = [
      { header: 'Invoice Year', key: 'IY', width: 15 },
      { header: 'Invoice Quarter', key: 'IQ', width: 15 },
      { header: 'Invoice Month', key: 'IM', width: 15 },
      { header: 'Invoice Date', key: 'ID', width: 15 },
      { header: 'Invoice Number', key: 'IN', width: 30 },
      { header: 'Category', key: 'Cat', width: 30 },
      { header: 'Supplier', key: 'Sup', width: 30 },
      { header: 'Description', key: 'Desc', width: 50 },
      { header: 'VAT region', key: 'VR', width: 10 },
      { header: 'Currency', key: 'Cur', width: 10 },
      { header: 'Amount (incl VAT)', key: 'AIV', width: 15 },
      { header: 'Amount (ex VAT)', key: 'AEV', width: 15 },
      { header: 'VAT', key: 'VAT', width: 15 },
  ];

  // Clean and prepare data
  const cleanData = invoiceData.map(invoice => {
      return {
          ...invoice,
          AEV: parseFloat(invoice.AEV.replace(/,/g, '')),
          VAT: parseFloat(invoice.VAT.replace(/,/g, '')),
          AIV: parseFloat(invoice.AIV.replace(/,/g, '')),
      };
  });

  cleanData.forEach(invoice => {
      worksheet.addRow(invoice);
  });

  const totalExVAT = cleanData.reduce((sum, invoice) => sum + (invoice.AEV || 0), 0);
  const totalVAT = cleanData.reduce((sum, invoice) => sum + (invoice.VAT || 0), 0);
  const totalInclVAT = cleanData.reduce((sum, invoice) => sum + (invoice.AIV || 0), 0);

  // Add summary section
  worksheet.addRow({});
  worksheet.addRow({ Desc: 'Summary:' });
  worksheet.addRow({ Desc: 'Total Amount (incl VAT)', AIV: totalInclVAT.toFixed(2) + ' EUR' });
  worksheet.addRow({ Desc: 'Total Amount (ex VAT)', AEV: totalExVAT.toFixed(2) + ' EUR' });
  worksheet.addRow({ Desc: 'Total VAT', VAT: totalVAT.toFixed(2) + ' EUR' });
  

  const buffer = await workbook.xlsx.writeBuffer();

  const timestamp = formatTimestamp();
  const filename = `invoices_${timestamp}.xlsx`;

  const file = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  if (userId) {
      // Upload to Firebase Storage for logged-in users
      const storageRef = ref(storage, `users/${userId}/files/${filename}`);

      await uploadBytes(storageRef, file).catch((uploadError) => {
          throw uploadError;
      });

      const downloadUrl = await getDownloadURL(storageRef);

      const fileData = {
          fileName: filename,
          fileUrl: downloadUrl,
          uploadDate: new Date(),
      };

      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          let remainingFiles = successFiles;
  
          // Calculate new quotas
          if (userData.extraQuota > 0) {
            if (userData.extraQuota >= remainingFiles) {
              userData.extraQuota -= remainingFiles;
              remainingFiles = 0;
            } else {
              remainingFiles -= userData.extraQuota;
              userData.extraQuota = 0;
            }
          }
  
          if (remainingFiles > 0) {
            userData.usedQuota += remainingFiles;
          }
  
          await updateDoc(userDocRef, {
            files: arrayUnion(fileData),
            usedQuota: userData.usedQuota,
            extraQuota: userData.extraQuota
          });
  
        } else {
          console.error("User document not found.");
        }
      } catch (updateError) {
          console.error("Update Firestore Error:", updateError);
      }

      setDownloadUrl({ url: downloadUrl, filename });
      setExcelFilename(filename);
      setFiles([]);
      return { success: true, downloadUrl, filename };
  } else {
      // Handle unauthenticated users by storing URL in localStorage
      const url = window.URL.createObjectURL(file);
      localStorage.setItem('downloadUrl', JSON.stringify({ url, filename }));
      setDownloadUrl({ url, filename });
      setExcelFilename(filename);
      setFiles([]);
      return { success: true, downloadUrl: url, filename };
  }
};