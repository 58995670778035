import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import './TaskHistory.css';
import { useTranslation } from 'react-i18next';

const TaskHistory = () => {
  const { t } = useTranslation();
  const user = useAuth();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTasks = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const files = userDoc.data().files || [];
            const taskList = files.map((file, index) => ({
              order: index + 1,
              fileName: file.fileName,
              uploadDate: file.uploadDate.toDate(),
              fileUrl: file.fileUrl,
            }));
            setTasks(taskList);
          }
        } catch (err) {
          console.error(t('Error fetching task history:'), err);
          setError(t('No task history.'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTasks();
  }, [user, t]);

  if (!user) {
    return <div>{t('Please log in to view your task history.')}</div>;
  }

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  if (error) {
    return <div className="history-error-message">{error}</div>;
  }

  return (
    <div className="task-history-container">
      <h2>{t('Task History')}</h2>
      <table className="task-history-table">
        <thead>
          <tr>
            <th className='hide-in-mobile'>{t('Order')}</th>
            <th>{t('File Name')}</th>
            <th>{t('Upload Date')}</th>
            <th>{t('Download')}</th>
          </tr>
        </thead>
        <tbody>
          {tasks?.map((task, index) => (
            <tr key={index}>
              <td className='hide-in-mobile'>{task.order}</td>
              <td>{task.fileName}</td>
              <td>{task.uploadDate.toLocaleString()}</td>
              <td>
                <a href={task.fileUrl} target="_blank" rel="noopener noreferrer">
                  {t('Download')}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TaskHistory;
