import React, { useState } from "react";
import { auth, db } from "../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import "./Auth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignUp = () => {
  const { t } = useTranslation(); // Initialize translation function
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const generateCustomerNumber = async () => {
    const today = new Date();
    const datePart = today.toISOString().split("T")[0].replace(/-/g, "");
    try {
      const q = query(
        collection(db, "users"),
        where("customerNumber", ">=", `${datePart}000`),
        where("customerNumber", "<", `${datePart}999`),
        orderBy("customerNumber", "desc"),
        limit(1)
      );
      const snapshot = await getDocs(q);
      let newNumber = `${datePart}001`; // Default first number of the day
      if (!snapshot.empty) {
        const lastNumber = snapshot.docs[0].data().customerNumber;
        const incrementedNumber = parseInt(lastNumber.slice(-3)) + 1;
        newNumber = `${datePart}${incrementedNumber
          .toString()
          .padStart(3, "0")}`;
      }
      return newNumber;
    } catch (error) {
      console.error(t("Error generating customer number:"), error);
      throw new Error(t("Failed to generate customer number"));
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8)
      return t("Password should be at least 8 characters long.");
    if (!/[a-z]/.test(password))
      return t("Password should contain at least one lowercase letter.");
    if (!/[A-Z]/.test(password))
      return t("Password should contain at least one uppercase letter.");
    if (!/[!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]+/.test(password))
      return t(
        "Password should contain at least one special character: ! @ # $ % ^ & * ( ) _ + - = [ ] { } ; : ' \" \\ | , . < > / ?"
      );

    return "";
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");

    const passwordError = validatePassword(password);
    if (passwordError) {
      setPasswordError(passwordError);
      return;
    }

    if (password !== confirmPassword) {
      setError(t("Passwords do not match."));
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      const customerNumber = await generateCustomerNumber();
      // Create user document in Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: email,
        customerNumber: customerNumber,
        firstName: "",
        lastName: "",
        country: "",
        state: "",
        city: "",
        address: "",
        postalCode: "",
        quota: 0,
        extraQuota: 100,
        usedQuota: 0,
        subscriptionStatus: "",
        subscriptionEndDate: null,
        files: {},
        companyInfo: {
          companyName: "",
          vatId: "",
          country: "",
          state: "",
          city: "",
          address: "",
          postalCode: "",
        },
        invoiceHistory: {},
      });

      localStorage.setItem("emailForSignIn", email);
      navigate("/check-email");
    } catch (err) {
      const errorMessage = rephraseError(err.message);
      setError(errorMessage);
      if (errorMessage.includes(t("already registered"))) {
        navigate("/login", { state: { email } });
      }
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);

      // Create user document in Firestore
      const user = result.user;
      const customerNumber = await generateCustomerNumber(); // Added customer number generation for Google signup
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        customerNumber: customerNumber,
        firstName: "",
        lastName: "",
        country: "",
        state: "",
        city: "",
        address: "",
        postalCode: "",
        quota: 0,
        extraQuota: 100,
        usedQuota: 0,
        subscriptionStatus: "",
        subscriptionEndDate: null,
        files: {},
        companyInfo: {
          companyName: "",
          vatId: "",
          country: "",
          state: "",
          city: "",
          address: "",
          postalCode: "",
        },
        invoiceHistory: {},
      });

      navigate("/");
    } catch (err) {
      setError(rephraseError(err.message));
    }
  };

  const rephraseError = (message) => {
    switch (message) {
      case "Firebase: Error (auth/email-already-in-use).":
        return t("This email is already registered. Redirecting to login...");
      case "Firebase: Error (auth/invalid-email).":
        return t("The email address is badly formatted.");
      case "Firebase: Error (auth/weak-password).":
        return t("Password should be at least 8 characters.");
      default:
        return t("An error occurred. Please try again.");
    }
  };

  const toLogin = () => navigate("/login");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className="auth-container">
      <h2>{t("Sign Up")}</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSignUp}>
        <input
          type="email"
          placeholder={t("Email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="password-container">
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder={t("Password")}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }}
            required
          />
          <FontAwesomeIcon
            icon={passwordVisible ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className="toggle-password-icon"
          />
        </div>
        <div className="password-container">
          <input
            type={confirmPasswordVisible ? "text" : "password"}
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <FontAwesomeIcon
            icon={confirmPasswordVisible ? faEyeSlash : faEye}
            onClick={toggleConfirmPasswordVisibility}
            className="toggle-password-icon"
          />
        </div>
        <p className="password-hint">
          {t("Password requirements")}:
          <ul>
            <li className={password.length >= 8 ? "met" : ""}>
              {t("At least 8 characters long")}
            </li>
            <li className={/[a-z]/.test(password) ? "met" : ""}>
              {t("At least 1 lowercase letter")}
            </li>
            <li className={/[A-Z]/.test(password) ? "met" : ""}>
              {t("At least 1 uppercase letter")}
            </li>
            <li
              className={
                /[!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?]+/.test(password)
                  ? "met"
                  : ""
              }
            >
              {t("At least 1 special character (e.g. !@#$%^&*)")}
            </li>
          </ul>
        </p>
        {passwordError && <p className="error">{passwordError}</p>}
        {errMsg && <p>{errMsg}</p>}
        <button className="auth-btn" type="submit">
          {t("Sign Up")}
        </button>
      </form>
      <button className="auth-btn" onClick={handleGoogleSignUp}>
        {t("Sign Up with Google")}
      </button>
      <p>
        {t("Already have an account?")}{" "}
        <button className="pass-btn" onClick={toLogin}>
          {t("Login")}
        </button>
      </p>
    </div>
  );
};

export default SignUp;
