import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./PaymentForm.css";
import { getNames } from "country-list";
import { useTranslation } from "react-i18next";
import SignupModal from "./SignupModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const apiUrl = process.env.REACT_APP_API_URL;
const countryOptions = getNames();

const PaymentForm = () => {
  const user = useAuth();
  const { t } = useTranslation();
  const [amount, setAmount] = useState(2.99);
  const [plan, setPlan] = useState("basic");
  const [vatNumber, setVatNumber] = useState("");
  const [isValidVat, setIsValidVat] = useState(false);
  const [vatError, setVatError] = useState("");
  const [vatMsg, setVatMsg] = useState("");
  const [areFieldsValid, setAreFieldsValid] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postalCode: "",
    customerNumber: "",
  });

  const [fieldValidity, setFieldValidity] = useState({
    firstName: true,
    lastName: true,
    country: true,
    city: true,
    address: true,
    postalCode: true,
  });

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    vatId: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
            setCompanyInfo(userDoc.data().companyInfo || {});
            setLoading(false);
          }
        } catch (error) {
          console.error(t("Error fetching user data:"), error);
        }
      }
    };

    fetchUserData();
  }, [user, t]);

  useEffect(() => {
    checkRequiredFields();
  }, [userData, vatNumber, isValidVat]);

  const handlePlanChange = (selectedPlan, selectedAmount) => {
    setPlan(selectedPlan);
    setAmount(selectedAmount);
  };

  // New function to handle plan click and show signup modal for non-logged-in users
  const handlePlanClick = (selectedPlan, selectedAmount) => {
    if (!user?.uid) {
      setShowSignupModal(true); // Show signup modal if user is not logged in
    } else {
      handlePlanChange(selectedPlan, selectedAmount);
      if (!areFieldsValid) {
        setButtonClicked(true);
        alert(t("Please fill in all required fields."));
        document.querySelector(".contact-us-container").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        handlePayment(selectedPlan);
      }
    }
  };

  const updateFieldValidity = (fieldName, value) => {
    setFieldValidity((prev) => ({
      ...prev,
      [fieldName]: !!value,
    }));
  };

  const validateVatNumber = async (vatNumber) => {
    try {
      const response = await axios.get(
        `http://www.apilayer.net/api/validate?access_key=${process.env.REACT_APP_VATLAYER_API_KEY}&vat_number=${vatNumber}`
      );
      return response.data.valid;
    } catch (error) {
      console.error(t("Error validating VAT number:"), error);
      return false;
    }
  };

  const handleVatChange = async (e) => {
    const vat = e.target.value;
    setVatNumber(vat);
    setCompanyInfo({ ...companyInfo, vatId: vat });
    if (vat) {
      const isValid = await validateVatNumber(vat);
      setIsValidVat(isValid);
      setVatError(isValid ? "" : t("Invalid VAT number"));
      setVatMsg(isValid ? t("Valid VAT number") : "");
    } else {
      setIsValidVat(false);
      setVatError("");
    }
  };

  const handlePayment = async (selectedPlan) => {
    setLoading(true);

    try {
      await updateDoc(doc(db, "users", user.uid), {
        ...userData,
        companyInfo,
      });

      const response = await axios.post(`${apiUrl}/create-checkout-session`, {
        customerEmail: user.email,
        userId: user.uid,
        plan: selectedPlan,
        vatNumber: companyInfo.vatId,
      });

      const { id } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      console.error(t("Error handling payment:"), error);
      setLoading(false);
    }
  };

  const handleExtraQuotaClick = () => {
    if (!areFieldsValid) {
      setButtonClicked(true);
      alert(t("Please fill in all required fields."));
      document.querySelector(".contact-us-container").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      handleExtraQuotaPayment();
    }
  };

  const handleExtraQuotaPayment = async () => {
    await updateDoc(doc(db, "users", user.uid), {
      ...userData,
      companyInfo,
    });

    const response = await axios.post(`${apiUrl}/purchase-extra-quota`, {
      amount: 1.99,
      customerEmail: user.email,
      userId: user.uid,
      vatNumber: companyInfo.vatId,
    });

    const { id } = response.data;
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId: id });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    setButtonClicked(false);
    checkRequiredFields();
    updateFieldValidity(name, value);
  };

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({ ...companyInfo, [name]: value });
  };

  const handleCountryChange = (e) => {
    setUserData({ ...userData, country: e.target.value });
    const value = e.target.value;
    setUserData({ ...userData, country: value });
    setButtonClicked(false);
    checkRequiredFields();
    updateFieldValidity("country", value);
  };

  const checkRequiredFields = () => {
    const { firstName, lastName, country, city, address, postalCode } =
      userData;
    if (firstName && lastName && country && city && address && postalCode) {
      setAreFieldsValid(true);
    } else {
      setAreFieldsValid(false);
    }
  };

  return (
    <div className="payment-form">
      {loading && (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
          <p>{t("Processing payment...")}</p>
        </div>
      )}

      {!loading && ( // Render form only when not loading
        <>
          <h1>
            <strong>{t("Choose Your Plan")}</strong>
          </h1>
          <div className="amount-selection">
            <div
              className={`plan ${
                plan === "basic" ? "selected basicPlan" : "basicPlan"
              }`}
              onClick={() => handlePlanClick("basic", 2.99)}
            >
              <h3>{t("Basic")}</h3>
              <p>{t("FOR INDIVIDUALS")}</p>
              <h2>
                <span>€</span>2.99
              </h2>
              <h4>
                {t("for")} <strong>50</strong> {t("invoices per month")}
              </h4>
            </div>
            <div
              className={`plan ${
                plan === "professional"
                  ? "selected standardPlan"
                  : "standardPlan"
              }`}
              onClick={() => handlePlanClick("professional", 9.99)}
            >
              <h3>{t("Professional")}</h3>
              <p>{t("MOST POPULAR")}</p>
              <h2>
                <span>€</span>9.99
              </h2>
              <h4>
                {t("for")} <strong>200</strong> {t("invoices per month")}
              </h4>
            </div>
            <div
              className={`plan ${
                plan === "premium" ? "selected premiumPlan" : "premiumPlan"
              }`}
              onClick={() => handlePlanClick("premium", 19.99)}
            >
              <h3>{t("Premium")}</h3>
              <p>{t("FOR POWER SELLERS")}</p>
              <h2>
                <span>€</span>19.99
              </h2>
              <h4>
                {t("for")} <strong>500</strong> {t("invoices per month")}
              </h4>
            </div>
          </div>

          {user?.uid ? (
            ""
          ) : (
            <>
              <div className="contact-us-container">
                <p>
                  {t("Need customized solution?")}{" "}
                  <a className="contact-link" href="mailto:info@vlisoft.com">
                    {t("Contact Us")} »
                  </a>
                </p>
              </div>
            </>
          )}

          {user?.uid ? (
            <>
              <div className="extra-quota-section">
                <h4>{t("Need More Quotas?")}</h4>
                <button onClick={() => handleExtraQuotaClick()}>
                  {t("Buy 30 Extra Quotas for €1.99")}
                </button>
              </div>
              <div className="contact-us-container">
                <p>
                  {t("Need customized solution?")}{" "}
                  <a className="contact-link" href="mailto:info@vlisoft.com">
                    {t("Contact Us")} »
                  </a>
                </p>
              </div>
              <div className="form-section">
                <h2>{t("Personal Information")}</h2>
                <form className="user-details-form">
                  <label>
                    <p>
                      {t("First Name")} <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className={`paymentInput ${
                        !fieldValidity.firstName
                          ? "input-error"
                          : "input-normal"
                      }`}
                      type="text"
                      name="firstName"
                      value={userData.firstName}
                      onChange={handleInputChange}
                      placeholder={t("Enter your first name")}
                      required
                    />
                  </label>
                  <label>
                    <p>
                      {t("Last Name")} <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      className={`paymentInput ${
                        !fieldValidity.lastName ? "input-error" : "input-normal"
                      }`}
                      type="text"
                      name="lastName"
                      value={userData.lastName}
                      onChange={handleInputChange}
                      placeholder={t("Enter your last name")}
                      required
                    />
                  </label>
                </form>
              </div>

              <div className="form-section">
                <h3>{t("Address Information")}</h3>
                <form className="user-address-form">
                  <div className="upper-container">
                    <label>
                      {t("Country")} <span style={{ color: "red" }}>*</span>
                      <input
                        className={`paymentInput ${
                          !fieldValidity.country
                            ? "input-error"
                            : "input-normal"
                        }`}
                        list="countryOptions"
                        name="country"
                        value={userData.country}
                        onChange={handleCountryChange}
                        placeholder={t("Select your country")}
                        required
                      />
                      <datalist id="countryOptions">
                        {countryOptions.map((country, index) => (
                          <option key={index} value={country} />
                        ))}
                      </datalist>
                    </label>
                    <label>
                      {t("State")}
                      <input
                        className="paymentInput"
                        type="text"
                        name="state"
                        value={userData.state}
                        onChange={handleInputChange}
                        placeholder={t("Enter your state")}
                      />
                    </label>
                    <label>
                      {t("City")} <span style={{ color: "red" }}>*</span>
                      <input
                        className={`paymentInput ${
                          !fieldValidity.city ? "input-error" : "input-normal"
                        }`}
                        type="text"
                        name="city"
                        value={userData.city}
                        onChange={handleInputChange}
                        placeholder={t("Enter your city")}
                        required
                      />
                    </label>
                  </div>
                  <div className="lower-container">
                    <label style={{ width: "80%" }}>
                      {t("Address")}{" "}
                      <span style={{ color: "red", marginRight: "50px" }}>
                        *
                      </span>
                      <input
                        className={`paymentInput ${
                          !fieldValidity.address
                            ? "input-error"
                            : "input-normal"
                        }`}
                        type="text"
                        name="address"
                        value={userData.address}
                        onChange={handleInputChange}
                        placeholder={t("Enter your address")}
                        required
                      />
                    </label>
                    <label style={{ width: "30%" }}>
                      {t("Postal Code")} <span style={{ color: "red" }}>*</span>
                      <input
                        className={`paymentInput ${
                          !fieldValidity.postalCode
                            ? "input-error"
                            : "input-normal"
                        }`}
                        type="text"
                        name="postalCode"
                        value={userData.postalCode}
                        onChange={handleInputChange}
                        placeholder={t("Enter your postal code")}
                        required
                      />
                    </label>
                  </div>
                </form>
              </div>

              <div className="form-section">
                <h3>{t("Company Information")}</h3>
                <form className="company-details-form">
                  <label>
                    {t("Company Name")}
                    <input
                      className="paymentInput"
                      type="text"
                      name="companyName"
                      value={companyInfo.companyName}
                      onChange={handleCompanyInputChange}
                      placeholder={t("Enter your company name")}
                    />
                  </label>
                  <label>
                    {t("VAT Number")}
                    <input
                      className="paymentInput"
                      type="text"
                      name="vatId"
                      value={companyInfo.vatId}
                      onChange={handleCompanyInputChange}
                      // onChange={handleVatChange}
                      placeholder={t("Enter your VAT number")}
                    />
                  </label>
                </form>
                {vatError && <span className="vat-error">{vatError}</span>}
                {vatMsg && <span className="vat-ok">{vatMsg}</span>}
              </div>

              {/* <button
            className={`paymentbtn ${loading ? "loading" : ""}`}
            onClick={handlePayment}
            disabled={
              !userData.firstName ||
              !userData.lastName ||
              !userData.country ||
              !userData.city ||
              !userData.address ||
              !userData.postalCode ||
              (vatNumber && !isValidVat) ||
              loading
            }
          >
            {loading && <span className="loading-spinner"></span>}
            {t("Proceed to Payment")}
          </button> */}
            </>
          ) : (
            <p>
              {t("Please")} <Link to="/signup">{t("Sign Up")}</Link>{" "}
              {t("first")}
            </p>
          )}
        </>
      )}

      <SignupModal
        show={showSignupModal}
        onHide={() => setShowSignupModal(false)}
      />
    </div>
  );
};

export default PaymentForm;
