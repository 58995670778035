import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AuthModal.css";

const SignupModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Sign Up Required")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t(
            "To take advantage of our premium features and upload more invoices, please sign up and enjoy a seamless experience."
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/login" className="navbar-button login" onClick={onHide}>
          {t("Login")}
        </Link>
        <Link to="/signup" className="navbar-button " onClick={onHide}>
          {t("Sign Up")}
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default SignupModal;
