import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './QuotaModal.css';

const QuotaModal = ({ show, onHide, quotaExceedMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToPricing = () => {
    onHide();
    navigate('/payment');
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('Quota Exceeded')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('You have exceeded your quota of uploads.')} {quotaExceedMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="login" onClick={onHide}>
          {t('Close')}
        </Button>
        <Button className="signup" onClick={handleGoToPricing}>
          {t('Pricing')}
        </Button>       
      </Modal.Footer>
    </Modal>
  );
};

export default QuotaModal;

