import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Auth.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [email, setEmail] = useState(useLocation().state?.email || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); 
  const navigate = useNavigate();
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (err) {
      setError(rephraseError(err.message));
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (err) {
      setError(rephraseError(err.message));
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError(t('Please enter your email address to reset password.'));
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage(t('Password reset email sent. Please check your inbox.'));
      setError('');
    } catch (err) {
      setError(rephraseError(err.message));
    }
  };

  const rephraseError = (message) => {
    switch (message) {
      case 'Firebase: Error (auth/invalid-credential).':
        return t('Invalid credentials. Please try again.');
      case 'Firebase: Error (auth/user-not-found).':
        return t('No account found with this email.');
      case 'Firebase: Error (auth/wrong-password).':
        return t('Incorrect password.');
      default:
        return t('An error occurred. Please try again.');
    }
  };

  const toSignup = () => navigate('/signup');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="auth-container">
      <h2>{t('Login')}</h2>
      {error && <p className="error-message">{error}</p>}
      {resetMessage && <p className="reset-message">{resetMessage}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder={t('Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        
        <div className="password-container">
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder={t('Password')}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
             
            }}
            required
          />
          <FontAwesomeIcon
            icon={passwordVisible ? faEyeSlash : faEye}
            onClick={togglePasswordVisibility}
            className="toggle-password-icon"
          />
        </div>
        <p className='forget-hint'><button className='pass-btn' onClick={handlePasswordReset}>{t('Reset Password')}</button></p>
        <button className='auth-btn' type="submit">{t('Login')}</button>
      </form>
      <button className='auth-btn' onClick={handleGoogleLogin}>{t('Login with Google')}</button>
      <p>{t('Don\'t have an account?')} <button className='pass-btn' onClick={toSignup}>{t('Sign Up')}</button></p>
    </div>
  );
};

export default Login;
