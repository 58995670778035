import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";
import "./NavBar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || i18n.language);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  let inactivityTimeout;
  const logoutTime = 15 * 60 * 1000; // after 15 minutes auto logout

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const toFrontPage = () => navigate("/");

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(handleLogout, logoutTime);
  };

  const handleActivity = () => {
    resetInactivityTimeout();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        resetInactivityTimeout();
        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keypress", handleActivity);
        window.addEventListener("click", handleActivity);
        window.addEventListener("touchstart", handleActivity);
      } else {
        setUser(null);
        clearTimeout(inactivityTimeout);
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keypress", handleActivity);
        window.removeEventListener("click", handleActivity);
        window.removeEventListener("touchstart", handleActivity);
      }
    });

    return () => {
      unsubscribe();
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, [auth]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <nav className="navbar">
      <div className="desktop-navbar">
        <div className="navbar-brand">
          <button className="title-btn" onClick={toFrontPage}>
            <span className="navbar-title">Invoices Auto</span>
          </button>
        </div>
        <div className="navbar-links">
          <Link to="/">{i18n.t("Home")}</Link>
          <Link to="/payment">{i18n.t("Pricing")}</Link>
          <Link to="/about-us">{i18n.t("About Us")}</Link>
        </div>
        <div className="navbar-actions">
          {user ? (
            <>
              <Link to="/dashboard" className="navbar-button login">
                {i18n.t("My Account")}
              </Link>
              <button className="navbar-button" onClick={handleLogout}>
                {i18n.t("Logout")}
              </button>
              <div className="navbar-button navbar-language-toggle">
                <select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  <option value="en">{i18n.t("English")}</option>
                  <option value="zh">{i18n.t("Chinese")}</option>
                  <option value="nl">{i18n.t("Dutch")}</option>
                </select>
              </div>
            </>
          ) : (
            <>
              <Link to="/login" className="navbar-button login">
                {i18n.t("Login")}
              </Link>
              <Link to="/signup" className="navbar-button signup">
                {i18n.t("Sign up")}
              </Link>
              <div className="navbar-button navbar-language-toggle">
                <select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                >
                  <option value="en">{i18n.t("English")}</option>
                  <option value="zh">{i18n.t("Chinese")}</option>
                  <option value="nl">{i18n.t("Dutch")}</option>
                </select>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mobile-navbar">
        <div className="navbar-brand">
          <button className="title-btn" onClick={toFrontPage}>
            <span className="navbar-title">Invoices Auto</span>
          </button>
          <button className="navbar-button menu" onClick={toggleMenu}>
            &#9776;
          </button>
        </div>
        {isMenuOpen && (
          <div className="navbar-links-mobile">
            {user ? (
              <>
                <div className="navbar-link-column">
                  <Link to="/dashboard">{i18n.t("My Account")}</Link>
                </div>
                <div className="navbar-link-column">
                  <button onClick={handleLogout}>{i18n.t("Logout")}</button>
                </div>
              </>
            ) : (
              <>
                <div className="navbar-link-column">
                  <Link to="/login">{i18n.t("Login")}</Link>
                </div>
                <div className="navbar-link-column">
                  <Link to="/signup">{i18n.t("Sign Up")}</Link>
                </div>
              </>
            )}
            <div className="navbar-link-column">
              <Link to="/">{i18n.t("Home")}</Link>
            </div>
            <div className="navbar-link-column">
              <Link to="/payment">{i18n.t("Pricing")}</Link>
            </div>
            <div className="navbar-link-column">
              <Link to="/about-us">{i18n.t("About Us")}</Link>
            </div>
            <div className="navbar-button navbar-language-toggle">
              <select
                value={language}
                onChange={(e) => changeLanguage(e.target.value)}
              >
                <option value="en">{i18n.t("English")}</option>
                <option value="zh">{i18n.t("Chinese")}</option>
                <option value="nl">{i18n.t("Dutch")}</option>
              </select>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
