import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../hooks/useAuth";
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

const PaymentCancel = () => {
  const { invoiceNumber } = useParams();
  const user = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const updateInvoiceStatus = async () => {
      if (user?.uid) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const invoiceHistory = userDoc.data().invoiceHistory.map(invoice =>
            invoice.invoiceNumber === invoiceNumber ? { ...invoice, status: 'Failed' } : invoice
          );

          await updateDoc(userDocRef, { invoiceHistory });
        }
      }
    };

    updateInvoiceStatus();
  }, [invoiceNumber, user]);

  return (
    <div className="payment-success-container" style={{"border":"none"}}>
      {t('Payment Canceled! You can try again.')}
    </div>
  );
};

export default PaymentCancel;
