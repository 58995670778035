import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import './InvoiceHistory.css';

const InvoiceHistory = () => {
  const { t } = useTranslation();
  const user = useAuth();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInvoices = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const invoiceData = userDoc.data().invoiceHistory || [];
            const formattedInvoices = invoiceData.map((invoice, index) => ({
              order: index + 1,
              invoiceDate: new Date(invoice.invoiceDate.seconds * 1000).toLocaleDateString(),
              amount: invoice.amount,
              paymentMethod: invoice.paymentMethod,
              downloadUrl: invoice.downloadUrl,
            }));
            setInvoices(formattedInvoices);
          }
        } catch (err) {
          setError(t('No invoice history.'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchInvoices();
  }, [user, t]);

  if (!user) {
    return <div>{t('Please log in to view your invoice history.')}</div>;
  }

  if (loading) {
    return <div>{t('Loading...')}</div>;
  }

  if (error) {
    return <div className="history-error-message">{error}</div>;
  }

  return (
    <div className="invoice-history-container">
      <h2>{t('Invoice History')}</h2>
      <table className="invoice-history-table">
        <thead>
          <tr>
            <th className='hide-in-mobile'>{t('Order')}</th>
            <th>{t('Invoice Date')}</th>
            <th>{t('Amount')}</th>
            <th className='hide-in-mobile'>{t('Payment Method')}</th>
            <th>{t('Download')}</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={index}>
              <td className='hide-in-mobile'>{invoice.order}</td>
              <td>{invoice.invoiceDate}</td>
              <td>{invoice.amount}</td>
              <td className='hide-in-mobile'>{invoice.paymentMethod}</td>
              <td>
                <a href={invoice.downloadUrl} target="_blank" rel="noopener noreferrer">
                  {t('Download')}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceHistory;
