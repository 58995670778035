import React, { useState, useEffect } from "react";
import { getNames } from "country-list";
import { useAuth } from "../hooks/useAuth";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./MyAccount.css";
import { Modal, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Generate country options for the input field
const countryOptions = getNames();
const apiUrl = process.env.REACT_APP_API_URL;

const MyAccount = () => {
  const { t } = useTranslation(); // Use the translation hook
  const user = useAuth();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    subscriptionStatus: "",
    subscriptionEndDate: null, // Track subscription end date
    invoiceHistory: [],
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          } else {
            setError(t("No user data found"));
          }
        } catch (error) {
          console.error(t("Error fetching user data:"), error);
          setError(t("Error fetching user data"));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [user, t]);

  const percentage = () => {
    return (
      (userData.usedQuota / (userData.quota + userData.extraQuota)) *
      100
    ).toFixed(2);
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    setUserData({
      ...userData,
      country: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user?.uid) {
      try {
        await updateDoc(doc(db, "users", user.uid), userData);
        setSuccess(t("Personal information updated successfully."));
      } catch (err) {
        setError(t("Failed to update personal information."));
      }
    }
  };

  const handleCancelSubscription = async () => {
    if (user?.uid) {
      try {
        const response = await fetch(`${apiUrl}/cancel-subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ customer_email: user.email }),
        });
        const data = await response.json();
        if (data.success) {
          setSuccess(t("Subscription cancellation scheduled successfully."));
          setUserData({
            ...userData,
            subscriptionStatus: "active",
            subscriptionEndDate: user.subscriptionEndDate,
          });
        } else {
          setError(
            data.message || t("Failed to schedule subscription cancellation.")
          );
        }
      } catch (error) {
        console.error(t("Error cancelling subscription:"), error);
        setError(t("An error occurred while cancelling the subscription."));
      }
    }
    setShowCancelModal(false);
  };

  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div>{t("Please log in to view your account details.")}</div>;
  }

  return (
    <div className="account-container">
      <div className="quota-bar">
        <h3>
          {t("Quota Usage")}{" "}
          <span>
            {userData.usedQuota} / {userData.quota + userData.extraQuota}{" "}
          </span>
        </h3>
        <div className="quota-meter">
          <div
            className="used-quota"
            style={{
              width: `${
                (userData.usedQuota / (userData.quota + userData.extraQuota)) *
                100
              }%`,
            }}
          >
            {percentage()}%
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="account-form">
        <label>
          {t("Email")}:
          <input
            className="disableInput"
            style={{ marginLeft: "62px" }}
            type="text"
            value={userData.email}
            disabled
          />
        </label>
        <label>
          {t("First Name")}:
          <input
            type="text"
            name="firstName"
            value={userData.firstName}
            onChange={handleChange}
          />
        </label>
        <label>
          {t("Last Name")}:
          <input
            type="text"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
          />
        </label>
        <label>
          {t("Country")}:
          <input
            style={{ marginLeft: "38px" }}
            list="countryOptions"
            name="country"
            value={userData.country}
            onChange={handleCountryChange}
          />
          <datalist id="countryOptions">
            {countryOptions.map((country, index) => (
              <option key={index} value={country} />
            ))}
          </datalist>
        </label>
        <label>
          {t("Subscription")}:
          <input
            className="disableInput"
            style={{ marginLeft: "38px" }}
            type="text"
            value={
              userData.subscriptionStatus === "active"
                ? `${t("Yes, ends at")} ${userData.subscriptionEndDate || t("TBD")}`
                : t("No")
            }
            disabled
          />
        </label>
        {userData.subscriptionStatus === "active" && (
          <>
            <label>
              {t("Subscription Plan")}:
              <input
                className="disableInput"
                style={{ marginLeft: "38px" }}
                type="text"
                value={
                  userData.invoiceHistory?.length > 0
                    ? userData.invoiceHistory[
                        userData.invoiceHistory.length - 1
                      ].amount === 2.99
                      ? t("Basic Plan")
                      : userData.invoiceHistory[
                          userData.invoiceHistory.length - 1
                        ].amount === 9.99
                      ? t("Professional Plan")
                      : t("Premium Plan")
                    : ""
                }
                disabled
              />
            </label>

            <Link to="/payment" className="navbar-button change-sub">
              {t("Change Plan")}
            </Link>
            {userData.subscriptionEndDate ? (
              <></>
            ) : (
              <>
                <button
                  className="navbar-button cancel-sub"
                  onClick={() => setShowCancelModal(true)}
                >
                  {t("Cancel Subscription")}
                </button>
              </>
            )}
          </>
        )}
        <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Cancel Subscription")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Are you sure you want to cancel your subscription?")}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="navbar-button login"
              onClick={() => setShowCancelModal(false)}
            >
              {t("Close")}
            </Button>
            <Button variant="danger" onClick={handleCancelSubscription}>
              {t("Cancel Subscription")}
            </Button>
          </Modal.Footer>
        </Modal>
        <button type="submit" className="account-button">
          {t("Update Profile")}
        </button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>
    </div>
  );
};

export default MyAccount;
