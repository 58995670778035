import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-container">
      <h1>{t('Our Story')}</h1>
      <div className="story-section">
        <p>
          {t('At ')}
          <a
            href="https://vlisoft.com"
            target="_blank"
            rel="noopener noreferrer"
            className="strong"
          >
            VliSoft
          </a>
          {t(', we are passionate about leveraging technology to solve real-world problems. Since our inception, we have been committed to developing innovative software solutions that drive business success. Our journey began with a vision to create a company that puts customer satisfaction at the forefront, ensuring that every solution we deliver is tailored to meet the specific needs of our clients.')}
        </p>
        <p>{t('Over the years, we have expanded our expertise across various domains, providing robust and scalable solutions that empower businesses to achieve their goals. Our team of dedicated professionals works tirelessly to stay ahead of industry trends, ensuring that we offer the latest and most effective technological solutions.')}</p>
      </div>
      <div className="mission-section">
        <h2>{t('Our Mission')}</h2>
        <p>{t('To empower businesses with innovative software solutions that drive success and foster growth.')}</p>
      </div>
      <div className="values-section">
        <h2>{t('Our Values')}</h2>
        <p>{t('Customer Focus: Understanding and meeting the unique needs of our clients.')}</p>
        <p>{t('Innovation: Staying ahead of industry trends to deliver cutting-edge solutions.')}</p>
        <p>{t('Excellence: Committing to the highest standards of quality in every project.')}</p>
      </div>
    </div>
  );
};

export default AboutUs;
