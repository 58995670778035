import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropZone from "./DropZone";
import FilePreview from "./FilePreview";
import Loading from "./Loading";
import { FaFileExcel } from "react-icons/fa";
import { uploadFiles, generateExcelAndUpload } from "../utils/api";
import AuthModal from "./AuthModal";
import QuotaModal from "./QuotaModal";
import InvoiceEditor from "./InvoiceEditor";
import { useAuth } from "../hooks/useAuth"; // Custom hook to get the current user
import { doc, updateDoc, increment, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { pdfjs } from "react-pdf";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "./UploadInvoice.css";
import "./DropZone.css";
import "./FilePreview.css";
import "./Loading.css";
import { Modal, Button, Form } from "react-bootstrap";

// Set the workerSrc for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const apiUrl = process.env.REACT_APP_API_URL;

function UploadInvoice() {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(t("Loading..."));
  const [excelFilename, setExcelFilename] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showQuotaModal, setShowQuotaModal] = useState(false);
  const [userQuota, setUserQuota] = useState(0);
  const [usedQuota, setUsedQuota] = useState(0);
  const [extraQuota, setExtraQuota] = useState(0);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [progress, setProgress] = useState({
    totalFiles: 0,
    success: 0,
    failed: 0,
    retryCount: 0,
  });
  const [uploadStart, setUploadStart] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [quotaExceedMessage, setQuotaExceedMessage] = useState("");

  const user = useAuth();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Feedback form states
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackName, setFeedbackName] = useState("");
  const [feedbackEmail, setFeedbackEmail] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackFiles, setFeedbackFiles] = useState([]);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const messages = [
    t("Loading..."),
    t("Normally it takes about 30 seconds"),
    t("While we are working, please take a break"),
    t("Almost done..."),
    t("Can't wait to see? We too!"),
  ];

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingMessage((prev) => {
          const nextIndex = (messages.indexOf(prev) + 1) % messages.length;
          return messages[nextIndex];
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        const errorElement = document.querySelector(".error-message");
        if (errorElement) {
          errorElement.classList.remove("flash");
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  // Fetch user quota and usedQuota if user is authenticated
  useEffect(() => {
    const fetchUserQuota = async () => {
      const currentUser = user;

      if (currentUser && currentUser.uid) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserQuota(data.quota || 0);
            setUsedQuota(data.usedQuota || 0);
            setExtraQuota(data.extraQuota || 0);
            setFeedbackEmail(data.email)
            setFeedbackName(data.firstName)
          }
        } catch (error) {
          console.error(t("Error fetching user quota:"), error);
        }
      }
    };
    fetchUserQuota();
  }, [user, t]);

  //pdf to jpeg
  const checkFileTypes = async (newFiles) => {
    const pdfFiles = newFiles.filter((file) => file.type === "application/pdf");
    const imageFiles = newFiles.filter((file) =>
      ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
    );

    const convertedFiles = await Promise.all(
      pdfFiles.map((file) => convertPdfToJpeg(file))
    );

    // Flatten the array of arrays from PDF conversions
    const flattenedConvertedFiles = convertedFiles.flat();

    // Combine both converted JPEGs and original image files
    return [...flattenedConvertedFiles, ...imageFiles];
  };

  const convertPdfToJpeg = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const pdfData = new Uint8Array(fileReader.result);
        const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
        const promises = [];

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          promises.push(convertPageToImage(pdf, pageNum, file.name));
        }

        const images = await Promise.all(promises);
        const compositeImage = await createCompositeImage(images);
        resolve(compositeImage);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };

      fileReader.readAsArrayBuffer(file);
    });
  };

  const convertPageToImage = async (pdf, pageNum, originalName) => {
    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 2.0 });

    const canvas = document.createElement("canvas");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const context = canvas.getContext("2d");
    await page.render({ canvasContext: context, viewport }).promise;

    const dataUrl = canvas.toDataURL("image/jpeg");

    // Create a unique name for each page
    const timestamp = Date.now();
    const uniqueName = `${originalName.replace(
      /\.pdf$/i,
      ""
    )}_page${pageNum}_${timestamp}.jpg`;

    // Convert data URL to a Blob and then to a File object
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    return new File([blob], uniqueName, { type: "image/jpeg" });
  };

  const createCompositeImage = (imageFiles) => {
    return new Promise((resolve) => {
      const imgElements = imageFiles.map((file) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        return img;
      });

      Promise.all(
        imgElements.map(
          (img) => new Promise((resolve) => (img.onload = resolve))
        )
      ).then(() => {
        const width = Math.max(...imgElements.map((img) => img.width));
        const height = imgElements.reduce((sum, img) => sum + img.height, 0);
        const compositeCanvas = document.createElement("canvas");
        compositeCanvas.width = width;
        compositeCanvas.height = height;
        const context = compositeCanvas.getContext("2d");

        let yOffset = 0;
        imgElements.forEach((img) => {
          context.drawImage(img, 0, yOffset, img.width, img.height);
          yOffset += img.height;
        });

        compositeCanvas.toBlob((blob) => {
          const uniqueName = `composite_${Date.now()}.jpg`;
          resolve(new File([blob], uniqueName, { type: "image/jpeg" }));
        }, "image/jpeg");
      });
    });
  };

  const handleFileChange = async (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = await checkFileTypes(newFiles);
  
    // Non-logged-in user check
    if (!user?.uid && files.length + validFiles.length > 2) {
      setErrorMessage(
        t("You can only upload up to 2 invoices for free. Sign up for more.")
      );
      setShowAuthModal(true);
      return;
    }
  
    // Logged-in user quota checks
    if (user?.uid) {
      if (!user?.emailVerified) {
        alert("Please verify your email");
        navigate(0);
      }
      const remainingQuota = userQuota + extraQuota - usedQuota;
  
      // Case 1: User has sufficient quota
      if (remainingQuota >= files.length + validFiles.length) {
        setFiles([...files, ...validFiles]);
        setErrorMessage("");
        return;
      }
  
      // Case 2: User has some quota left, but not enough for all files
      if (remainingQuota > 2) {
        setErrorMessage(
          t(`You have exceeded your quota of uploads. You can upload up to ${remainingQuota} invoices.`)
        );
        setShowQuotaModal(true);
        setQuotaExceedMessage(t('You can upload up to invoices', { count: remainingQuota }));
        return;
      }
  
      // Case 3: User has no quota left but can upload up to 2 invoices
      if (remainingQuota < 2 && files.length + validFiles.length > 2) {
        setErrorMessage(
          t("You have exceeded your quota of uploads. You can still upload up to 2 invoices each time unless you upgrade your plan or buy new quota.")
        );
        setShowQuotaModal(true);
        setQuotaExceedMessage(
          t("You can still upload up to 2 invoices each time unless you upgrade your plan or buy new quota.")
        );
        return;
      }
    }
  
    setFiles([...files, ...validFiles]);
    setErrorMessage("");
  };
  
  const handleDrop = async (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    const validFiles = await checkFileTypes(newFiles);
  
    // Non-logged-in user check
    if (!user?.uid && files.length + validFiles.length > 2) {
      setErrorMessage(
        t("You can only upload up to 2 invoices for free. Sign up for more.")
      );
      setShowAuthModal(true);
      return;
    }
  
    // Logged-in user quota checks
    if (user?.uid) {
      if (!user?.emailVerified) {
        alert("Please verify your email");
        navigate(0);
      }

      const remainingQuota = userQuota + extraQuota - usedQuota;
  
      // Case 1: User has sufficient quota
      if (remainingQuota >= files.length + validFiles.length) {
        setFiles([...files, ...validFiles]);
        setErrorMessage("");
        return;
      }
  
      // Case 2: User has some quota left, but not enough for all files
      if (remainingQuota > 2) {
        setErrorMessage(
          t(`You have exceeded your quota of uploads. You can upload up to ${remainingQuota} invoices.`)
        );
        setShowQuotaModal(true);
        setQuotaExceedMessage(t(`You can upload up to ${remainingQuota} invoices.`));
        return;
      }
  
      // Case 3: User has no quota left but can upload up to 2 invoices
      if (remainingQuota < 2  && files.length + validFiles.length > 2) {
        setErrorMessage(
          t("You have exceeded your quota of uploads. You can still upload up to 2 invoices each time unless you upgrade your plan or buy new quota.")
        );
        setShowQuotaModal(true);
        setQuotaExceedMessage(
          t("You can still upload up to 2 invoices each time unless you upgrade your plan or buy new quota.")
        );
        return;
      }
    }
  
    setFiles([...files, ...validFiles]);
    setErrorMessage("");
  };  

  const handleDragOver = (e) => {
    e.preventDefault();
    document.querySelector(".drop-zone").classList.add("drag-over");
    if (user?.uid && !user?.emailVerified) {
      alert(t('Please verify your email'));  
      document.querySelector(".drop-zone").classList.remove("drag-over");
      navigate(0);
    }
  };

  const handleDragEnter = (e) => {
    if (
      !user?.emailVerified &&
      document.getElementById("fileInput").files.length > 2
    ) {
      document.querySelector(".drop-zone").classList.add("drag-over");
      alert(t("Please signup or verify your email"));
      navigate(0);
    }

    e.preventDefault();
    document.querySelector(".drop-zone").classList.add("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    document.querySelector(".drop-zone").classList.remove("drag-over");
  };

  const handleRemoveFile = (index) => {
    if (!loading) {
      setFiles(files.filter((_, i) => i !== index));
    }
  };

  const handleClearDownload = () => {
    setProgress({ totalFiles: 0, success: 0, failed: 0, retryCount: 0 });
    setDownloadUrl("");
    setExcelFilename("");
    setFiles([]); // Reset files
    setLoading(false); // Reset loading state
    setLoadingMessage(t("Loading...")); // Reset loading message
    setError("");
    setSuccess("");
  };

  const handleReCaptchaValidation = async () => {
    if (!executeRecaptcha) {
      setErrorMessage(t("reCAPTCHA not ready."));
      return false;
    }

    try {
      const token = await executeRecaptcha("upload");

      // Verify the token with your server
      const response = await fetch(`${apiUrl}/verify-recaptcha`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();

      if (data.success && data.score > 0.5) {
        return true;
      } else {
        setErrorMessage(t("reCAPTCHA validation failed. Please try again."));
        return false;
      }
    } catch (error) {
      console.error(t("reCAPTCHA error:"), error);
      setErrorMessage(t("Error validating reCAPTCHA. Please try again."));
      return false;
    }
  };

  const handleUpload = async () => {
    setVerifying(true);

    const isRecaptchaValid = await handleReCaptchaValidation();
    if (!isRecaptchaValid) {
      setVerifying(false);
      return;
    } else {
      setVerifying(false);
    }

    if (user?.uid && files.length + usedQuota > userQuota + extraQuota) {
      setShowQuotaModal(true);
      setVerifying(false);
      return;
    }

    setLoading(true);
    let result;

    try {
      if (user?.uid) {
        result = await uploadFiles(
          files,
          user.uid,
          setDownloadUrl,
          setExcelFilename,
          setFiles,
          3,
          setProgress,
          setLoading,
          setUploadStart,
          usedQuota,
          setUsedQuota,
          setInvoiceData
        );
      } else {
        result = await uploadFiles(
          files,
          null,
          setDownloadUrl,
          setExcelFilename,
          setFiles,
          3,
          setProgress,
          setLoading,
          setUploadStart,
          setInvoiceData
        );
      }

      if (result.success) {
        setInvoiceData(result.data);
        setIsEditing(true);
      } else {
        setErrorMessage(result ? result.error : t("An unknown error occurred."));
      }
    } catch (error) {
      console.error(t("Error during file upload:"), error);
      setErrorMessage(t("An unexpected error occurred. Please try again."));
      setLoading(false);
      return;
    }

    setLoading(false);
    setRecaptchaToken("");
  };


  const handleAuthModalClose = () => {
    setShowAuthModal(false);
    setErrorMessage("");
    navigate(0);
  };

  const handleSaveEditedData = async (editedData) => {
    setLoading(true);
    try {
      const result = await generateExcelAndUpload(
        editedData,
        user?.uid,
        setDownloadUrl,
        setExcelFilename,
        setFiles,
        files.length,
        usedQuota
      );

      if (result?.success) {
        setIsEditing(false); // Exit editing mode
        setInvoiceData([]); // Clear the invoice data
      } else {
        setErrorMessage(
          result ? result.error : t("Failed to save the edited data.")
        );
      }
    } catch (error) {
      console.error(t("Error saving edited data:"), error);
      setErrorMessage(t("An error occurred while saving the edited data."));
    } finally {
      setLoading(false);
    }
  };

  // Cancel editing and reset
  const handleCancelEditing = () => {
    setIsEditing(false);
    setInvoiceData(null);
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    setFeedbackLoading(true);
    setError("");
    setSuccess("");

    const formData = new FormData();
    formData.append("name", feedbackName);
    formData.append("email", feedbackEmail);
    formData.append("message", feedbackMessage);
    feedbackFiles.forEach((file, index) => {
      formData.append(`feedbackFiles`, file);
    });

    try {
      const response = await fetch(`${apiUrl}/send-feedback`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        setSuccess(t("Feedback sent successfully!"));
        setFeedbackName("");
        setFeedbackEmail("");
        setFeedbackMessage("");
        setFeedbackFiles([]);
      } else {
        setError(t("Failed to send feedback."));
      }
    } catch (error) {
      setError(t("An error occurred while sending feedback."));
      console.error(t("Feedback error:"), error);
    } finally {
      setFeedbackLoading(false);
      setShowFeedbackModal(false);
    }
  };

  // File input handler for feedback form
  const handleFeedbackFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFeedbackFiles([...feedbackFiles, ...newFiles]);
  };

  const handleQuotaModalClose = () =>{
    setShowQuotaModal(false);
    setQuotaExceedMessage("");
    document.querySelector(".drop-zone").classList.remove("drag-over");
    navigate(0);
  }

  return (
    <div>
      <AuthModal show={showAuthModal} onHide={() => handleAuthModalClose()} />
      <QuotaModal
        show={showQuotaModal}
        onHide={() => handleQuotaModalClose()}
        quotaExceedMessage={quotaExceedMessage}
      />

      {!loading && !downloadUrl && files.length === 0 && (
        <DropZone
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleDragEnter={handleDragEnter}
          handleDragLeave={handleDragLeave}
          handleFileChange={handleFileChange}
          loading={loading}
          errorMessage={errorMessage}
          setShowAuthModal={setShowAuthModal}
        />
      )}
      {!loading && !uploadStart && !isEditing && files.length > 0 && (
        <>
          <div className="mobile-buttons">
            <button
              onClick={handleUpload}
              disabled={loading || files.length === 0}
              className="main-button"
            >
              {loading && !isEditing ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {t("Generating Excel...")}
                </>
              ) : (
                t("Let's Start!")
              )}
            </button>
            <button className="cancel-button" onClick={handleClearDownload}>
              {t("Cancel")}
            </button>
          </div>
          <FilePreview
            files={files}
            handleRemoveFile={handleRemoveFile}
            loading={loading}
          />

          <div className="buttons preview-buttons">
            <button
              onClick={handleUpload}
              disabled={loading || verifying || files.length === 0}
              className={`main-button ${verifying ? "verifying" : ""}`}
            >
              {verifying ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {t("Generating Excel...")}
                </>
              ) : (
                t("Let's Start!")
              )}
            </button>
            <button className="cancel-button" onClick={handleClearDownload}>
              {t("Cancel")}
            </button>
          </div>
        </>
      )}

      {isEditing && !downloadUrl && (
        <InvoiceEditor
          invoiceData={invoiceData}
          onSave={handleSaveEditedData}
          onCancel={handleCancelEditing}
        />
      )}

      {uploadStart && downloadUrl === "" && (
        <Loading
          loadingMessage={loadingMessage}
          progress={progress}
          files={files}
        />
      )}
      {!loading && downloadUrl && !isEditing && (
        <div className="download-section">
          <button className="download-button">
            <a
              href={downloadUrl.url}
              download={downloadUrl.filename}
              className="download-link"
              onClick={(e) => {
                if (!user?.uid) {
                  e.preventDefault();
                  setShowDownloadModal(true);
                }
              }}
            >
              {t("Download Excel")}
            </a>
          </button>
          <p>
            <FaFileExcel />
            {downloadUrl.filename}
          </p>
          <button className="retry-button" onClick={handleClearDownload}>
            {t("Try again?")}
          </button>

          <Button
            className="feedback-button"
            onClick={() => setShowFeedbackModal(true)}
          >
            {t("Leave us a comment")}
          </Button>
          {error && <p className="text-danger mt-3">{error}</p>}
          {success && <p className="text-success mt-3">{success}</p>}
          <Modal
            show={showDownloadModal}
            onHide={() => setShowDownloadModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Sign Up to Download")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {t("Want to save time and upload ALL invoices at once?")}
                <br /> {t("Sign Up now and receive")} <span className="strong">100 </span> {t("FREE invoice quotas!")}
              </p>
              <p>
                {t("You can use it for FREE by uploading up to 2 invoices at a time.")}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="down-btn">
                <a
                  href={downloadUrl.url}
                  download={downloadUrl.filename}
                  className="download-link"
                >
                  {t("FREE Download")}
                </a>
              </Button>
              <Button
                className="login"
                onClick={() => setShowDownloadModal(false)}
              >
                {t("Cancel")}
              </Button>
              <Button className="signup" onClick={() => navigate("/signup")}>
                {t("Sign Up")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}

      {/* Feedback Form */}

      <Modal
        show={showFeedbackModal}
        onHide={() => setShowFeedbackModal(false)}
      >
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Form onSubmit={handleSubmitFeedback}>
          <Modal.Body>
            <Form.Group controlId="feedbackName">
              <Form.Label style={{textAlign:"start", width:"98%"}}>{t("Name")}</Form.Label>
              <Form.Control
                type="text"
                value={feedbackName}
                onChange={(e) => setFeedbackName(e.target.value)}
                placeholder={t("Enter your name")}
                required
              />
            </Form.Group>
            <Form.Group controlId="feedbackEmail" className="mt-3">
              <Form.Label style={{textAlign:"start", width:"98%"}}>{t("Email")}</Form.Label>
              <Form.Control
                type="email"
                value={feedbackEmail}
                onChange={(e) => setFeedbackEmail(e.target.value)}
                placeholder={t("Enter your email")}
                required
              />
            </Form.Group>
            <Form.Group controlId="feedbackMessage" className="mt-3">
              <Form.Label style={{textAlign:"start", width:"98%"}}>{t("Message")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={feedbackMessage}
                onChange={(e) => setFeedbackMessage(e.target.value)}
                placeholder={t("Enter your message")}
                required
              />
            </Form.Group>
            <Form.Group controlId="feedbackFiles" className="mt-3">
              <Form.Label style={{textAlign:"start", width:"98%"}}>{t("Upload Images")}</Form.Label>
              <Form.Control
                type="file"
                multiple
                name="feedbackFiles"
                onChange={handleFeedbackFileChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="login"
              onClick={() => setShowFeedbackModal(false)}
            >
              {t("Close")}
            </Button>
            <Button
              className="signup"
              style={{ minWidth: "150px" }}
              type="submit"
              disabled={feedbackLoading}
            >
              {feedbackLoading ? t("Sending...") : t("Send Feedback")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default UploadInvoice;


