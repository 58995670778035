import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import MyAccount from '../components/MyAccount';
import CompanyInformation from '../components/CompanyInformation';
import TaskHistory from '../components/TaskHistory';
import InvoiceHistory from '../components/InvoiceHistory';
import './DashboardPage.css';
import { useTranslation } from 'react-i18next';

const DashboardPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('my-account');

  useEffect(() => {
    // Set the active tab to "my-account" on initial load
    setActiveTab('my-account');
  }, []);

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <ul>
          <li className={activeTab === 'my-account' ? 'active' : ''}>
            <Link to="/dashboard/my-account" onClick={() => setActiveTab('my-account')}>
              {t('My Account')}
            </Link>
          </li>
          <li className={activeTab === 'company-info' ? 'active' : ''}>
            <Link to="/dashboard/company-info" onClick={() => setActiveTab('company-info')}>
              {t('Company Information')}
            </Link>
          </li>
          <li className={activeTab === 'task-history' ? 'active' : ''}>
            <Link to="/dashboard/task-history" onClick={() => setActiveTab('task-history')}>
              {t('Task History')}
            </Link>
          </li>
          <li className={activeTab === 'invoice-history' ? 'active' : ''}>
            <Link to="/dashboard/invoice-history" onClick={() => setActiveTab('invoice-history')}>
              {t('Invoice History')}
            </Link>
          </li>
        </ul>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard/my-account" />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="company-info" element={<CompanyInformation />} />
          <Route path="task-history" element={<TaskHistory />} />
          <Route path="invoice-history" element={<InvoiceHistory />} />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardPage;

