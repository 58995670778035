import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Loading.css";

const Loading = ({ loadingMessage, progress, files }) => {
  const { t } = useTranslation();
  const { success, failed } = progress;
  const [successFiles, setSuccessFiles] = useState(0);

  useEffect(() => {
    if (success !== undefined) {
      setSuccessFiles(prevSuccess => prevSuccess + success);
    }
  }, [success]);

  return (
    <div className="loading-container">
      <div className="loading-circle"></div>
      <div className="loading">{loadingMessage}</div>
      <div className="progress-container">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${((successFiles || 0.01) / files.length) * 100}%` }}
          ></div>
        </div>
        <h4>{t("Files Uploaded")}: {files.length}</h4>
        <p>
          {t("Success Process")}{" "}
          <span className="strong">{successFiles}</span> {t("invoices")}
        </p>
        <p>{failed} {t("invoices need further analysis")}</p>
      </div>
    </div>
  );
};

export default Loading;
