import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from 'react-i18next';

const DropZone = ({
  handleDrop,
  handleDragOver,
  handleDragEnter,
  handleDragLeave,
  handleFileChange,
  loading,
  setShowAuthModal,
}) => {
  const { t, i18n } = useTranslation();
  const user = useAuth();
  const navigate = useNavigate();

  const handleFileInputClick = () => {
    const fileInput = document.getElementById("fileInput");
    const fileCount = fileInput?.files?.length || 0;

    if (!user?.uid) {
      if (fileCount >= 2) {
        setShowAuthModal(true);
      } else {
        fileInput.click();
      }
    } else if (!user?.emailVerified) {
      alert(t('Please verify your email'));
      navigate(0);
    } else if (!loading) {
      fileInput.click();
    }
  };

  return (
    <div
      className="drop-zone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <h1>
        <strong>{t('Business Automated Invoice Tool')}</strong>
      </h1>
      <p>
        {t('Get detailed invoice data, categories, financial summaries, and an user-friendly Excel layout from the invoices with a')}
        <br />
        <span className="strong free-font">{t('Single Click!')}</span>
        {user?.uid ? (
          ""
        ) : (
          <>
            {" "}{t("Let's try for")} <span className="strong free-font">{t('FREE')}</span>
          </>
        )}
        <br />
        {t('Supports')}{" "}
        <span className="strong">
          {t('PDF and image formats (PNG, JPG, JPEG) for seamless processing.')}
        </span>
      </p>

      <input
        id="fileInput"
        type="file"
        onChange={handleFileChange}
        multiple
        accept="image/png, image/jpg, image/jpeg, application/pdf"
        style={{ display: "none" }}
      />
      <button
        className="select-files-button"
        onClick={handleFileInputClick}
        disabled={loading}
      >
        {t('Click to select files')}
      </button>
      <p className="drag-msg">{t('Or drag and drop files here')}</p>
      <p className="description">
        {t('Take it easy! Processing your data from')}{" "}
        <span className="strong">{t('10 seconds')}</span>,{" "}
        {t('depending on the number of invoices.')}
      </p>
    </div>
  );
};

export default DropZone;
