import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';
import './PaymentPage.css';

const stripePromise = loadStripe('your-publishable-key-here');

const PaymentPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="payment-page">
      
        <PaymentForm />
        <div className="legal-disclaimer">
        </div>
      </div>
    </Elements>
  );
};

export default PaymentPage;
