import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UploadInvoice from "./components/UploadInvoice";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import CheckEmail from "./components/CheckEmail";
import EmailVerification from "./components/EmailVerification";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentCancel from "./components/PaymentCancel";
import DashboardPage from "./pages/DashboardPage";
import PaymentPage from "./pages/PaymentPage";
import AboutUs from "./pages/AboutUs"
import PrivacyPolicy from './pages/PrivacyPolicyPage'
import TermsOfService from './pages/TermsOfServicePage'
import { AuthProvider } from "./hooks/useAuth";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <AuthProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6Ld0p_opAAAAAPAy0KSqFp3wJRWB47RVgSJvV1pq">
      <Router>
        <div className="App">
          <NavBar />
          <Routes>
          
            <Route exact path="/" element={<UploadInvoice />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route path="/check-email" element={<CheckEmail />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />
            <Route path="/dashboard/*" element={<DashboardPage />} />
            <Route path="/payment" element={<PaymentPage/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/terms" element={<TermsOfService/>} />
          </Routes>
          
          <Footer />
        </div>
      </Router>
      </GoogleReCaptchaProvider>
    </AuthProvider>
  );
}

export default App;
