import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import './InvoiceEditor.css';

const InvoiceEditor = ({ invoiceData, onSave, onCancel }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(invoiceData);
  const [history, setHistory] = useState([invoiceData]);
  const [historyIndex, setHistoryIndex] = useState(0);

  useEffect(() => {
    setData(invoiceData);
    setHistory([invoiceData]);
    setHistoryIndex(0);
  }, [invoiceData]);

  const handleInputChange = (e, rowIndex, key) => {
    const newValue = e.target.value;
    const updatedData = data.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [key]: newValue };
      }
      return row;
    });
    setData(updatedData);
    addToHistory(updatedData);
  };

  const handleSave = () => {
    onSave(data);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setData(history[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setData(history[historyIndex + 1]);
    }
  };

  const handleReset = () => {
    setData(invoiceData);
    setHistory([invoiceData]);
    setHistoryIndex(0);
  };

  const addToHistory = (newData) => {
    const newHistory = history.slice(0, historyIndex + 1);
    setHistory([...newHistory, newData]);
    setHistoryIndex(newHistory.length);
  };

  const calculateSummary = (data) => {
    const totalExVAT = data.reduce((sum, row) => sum + parseFloat(row.AEV || 0), 0);
    const totalVAT = data.reduce((sum, row) => sum + parseFloat(row.VAT || 0), 0);
    const totalInclVAT = data.reduce((sum, row) => sum + parseFloat(row.AIV || 0), 0);
    return { totalExVAT, totalVAT, totalInclVAT };
  };

  const { totalExVAT, totalVAT, totalInclVAT } = calculateSummary(data);

  return (
    <div className="invoice-editor-container">
      <h3>{t('View Invoice Data')}</h3>
      <p>{t('AI did its best—give it a human once-over for the final touch!')}</p>
      <div className="invoice-editor-table-wrapper">
        <table className="invoice-editor-table">
          <thead>
            <tr>
              <th>{t('Invoice Year')}</th>
              <th>{t('Invoice Quarter')}</th>
              <th>{t('Invoice Month')}</th>
              <th>{t('Invoice Date')}</th>
              <th>{t('Invoice Number')}</th>
              <th>{t('Category')}</th>
              <th style={{"min-width":"200px"}}>{t('Supplier')}</th>
              <th style={{"min-width":"200px"}}>{t('Description')}</th>
              <th>{t('VAT Region')}</th>
              <th>{t('Currency')}</th>
              <th style={{"min-width":"120px"}}>{t('Amount (incl VAT)')}</th>
              <th style={{"min-width":"120px"}}>{t('Amount (excl VAT)')}</th>
              <th style={{"min-width":"120px"}}>{t('VAT')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(row).map((key, index) => (
                  <td key={index}>
                    <input
                      className="invoice-editor-input"
                      type="text"
                      value={row[key]}
                      onChange={(e) => handleInputChange(e, rowIndex, key)}
                    />
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td colSpan={10} style={{ textAlign: 'right' }}>{t('Summary')}:</td>
              <td>{totalInclVAT.toFixed(2)} EUR</td>
              <td>{totalExVAT.toFixed(2)} EUR</td>
              <td>{totalVAT.toFixed(2)} EUR</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="invoice-editor-buttons">
        <button
          className="invoice-editor-button"
          onClick={handleSave}
        >
          {t('Save')}
        </button>
        <button
          className="invoice-editor-button invoice-editor-reset-button"
          onClick={handleReset}
        >
          {t('Reset')}
        </button>
        <button
          className="invoice-editor-button"
          onClick={handleUndo}
          disabled={historyIndex === 0}
        >
          {t('Undo')}
        </button>
        <button
          className="invoice-editor-button"
          onClick={handleRedo}
          disabled={historyIndex === history.length - 1}
        >
          {t('Redo')}
        </button>
        <button
          className="invoice-editor-button"
          onClick={onCancel}
        >
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};

export default InvoiceEditor;
