import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <h1>{t('Privacy Policy')}</h1>
      <p>{t('Effective Date')}: <strong>{t('June 19, 2024')}</strong></p>

      <section>
        <h2>{t('Introduction')}</h2>
        <p>
          {t('At VliSoft, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this privacy policy carefully.')}
        </p>
      </section>

      <section>
        <h2>{t('Information We Collect')}</h2>
        <p>
          {t('We collect personal information that you provide to us, such as your name, address, contact information, and financial information. We also collect information automatically as you navigate through the site.')}
        </p>
      </section>

      <section>
        <h2>{t('How We Use Your Information')}</h2>
        <p>
          {t('We use the information we collect to process your payments, handle your invoices, and provide you with personalized services.')}
        </p>
      </section>

      <section>
        <h2>{t('Data Protection and GDPR Compliance')}</h2>
        <p>
          {t('We comply with EU and Dutch data protection laws, including GDPR. Your data is stored securely and processed in accordance with legal requirements. You have the right to access, correct, or delete your personal information.')}
        </p>
      </section>

      <section>
        <h2>{t('Data Sharing and Disclosure')}</h2>
        <p>
          {t('We may share your information with third parties for business purposes or as required by law. We do not sell your personal information to third parties.')}
        </p>
      </section>

      <section>
        <h2>{t('Security')}</h2>
        <p>
          {t('We use administrative, technical, and physical security measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.')}
        </p>
      </section>

      <section>
        <h2>{t('Changes to This Policy')}</h2>
        <p>
          {t('We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.')}
        </p>
      </section>

      <section>
        <h2>{t('Contact Us')}</h2>
        <p>
          {t('If you have any questions or concerns about this Privacy Policy, please contact us at: ')}
          <a href="mailto:info@vlisoft.com">info@vlisoft.com</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
